import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddEventYearMutation, useDeleteEventYearMutation, useGetAllYearsQuery } from '../Services/modules/PhotoGallery';
import useDataTable from '../hooks/useDataTable';
import { updateGalleryData } from '../Store/GalleryData';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../Components/WarningModal';
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

function PhotoGallery() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {   
    handleFirstPage ,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex  ,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
    // Add other state variables and functions here
  }=useDataTable()
  const{
    data=[],
    isSuccess,
    isLoading,
    error,
    refetch
  }=useGetAllYearsQuery({page_number:page,page_size:rowsPerPage})
  const [year_data, setYearData] = useState({
    year_id:"",
    year: "",       
  });
  const[isDelete,setIsDelete]=useState(false) 
  const[isDeleteModal,setDeleteModal]=useState(false)
  const[year_list,setYear]=useState([]) 
  const [isSumbmiting,setIsSubmitting]=useState(false)

  const [AddYear, { data:addYearData, isSuccess:addYearIsSuccess, isLoading:addYearIsLoading, isError:addYearIsError, error:addYearError }] = useAddEventYearMutation();
  const [DeleteYear, { 
    data:deleteYearData=[], 
    isSuccess:deleteYearIsSuccess, 
    isLoading:deleteYearIsLoading, 
    isError:deleteYearIsError, 
    error:deleteYearError }] = useDeleteEventYearMutation();

    function handleChange(name, value) {
      setYearData({
        ...year_data,
        [name]: value
      });
    }
 const handleYearDelete=(dataRow)=>{
       console.log("In handle product delete dataRow",dataRow)
        setDeleteModal(true)
        setYearData({
          ...year_data,
          year_id:dataRow._id,         
        });

      }
      useEffect(() => {
        refetch();
      }, [page]);
      useEffect(()=>{

        if(isSuccess)
        {
          console.log("dataProduct kddddddddd",data)
          setYear(data.data)
          setTotalRows(data.totalRows)

        }
          },[data])
          useEffect(() => {
            if(addYearIsSuccess)
            {
              toast.success(addYearData.message)
              setIsSubmitting(false)
          refetch()

            }
          },[addYearData])
          useEffect(() => {
            if(deleteYearIsSuccess)
            {
              toast.success(deleteYearData.message)
              setIsDelete(false)
              setDeleteModal(false)
              refetch()
            }
          },[deleteYearData])

          useEffect(() => {
           
            if(isDelete)
            {
              DeleteYear(year_data)
            }
           
          
          
          }, [isDelete]);

          const navigateToGalleryDetails=(galleryDetails)=>{
            dispatch(updateGalleryData({data:galleryDetails}))
            navigate({
              pathname: '/photogalleryevent',
              // search: `?id=${courseDetails._id}`,    
            });
      
           }
           const onSubmit=()=>{
          setIsSubmitting(true)
              AddYear(year_data)
    
            
          }
    
    const photoGalleryScreen=()=>{
      return(
        <>
        <ToastContainer/>
        <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
              <main id="main" class="main">
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='card'>
                        <div className='card-body'>
              <h5 className="card-title">Add Year</h5>
              <div className="form-group">
                  <label htmlFor="input1">Year</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="year"
                  value={year_data.year}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                   />
                </div>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' :                  
                  'Submit'} 
                 
                </button>
                        </div>
                      </div>
                    </div>
                  
                    <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body'>
                        <table className="bordered-table">
        <thead>
          <tr>
          <th>Action</th>
            <th>Index</th>
            <th>Year</th>
            {/* Add more column headers as needed */}
          </tr>
        </thead>
        <tbody>
          {year_list.map((item, index) => (
            <tr key={index}>
              <td>                
              <MdDelete onClick={()=>handleYearDelete(item)} style={{cursor:'pointer'}}/>
              </td>
              <td>{sequenceStartIndex + index}</td>
              <td>
              <a onClick={()=>navigateToGalleryDetails(item)} style={{cursor:'pointer',color:'blue'}}>

                {item.year}
                </a>
                </td>             
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button>
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
      <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button>
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div>
     
                        </div>
                    </div>
                </div>
                  </div>
                </div>
                </main>
        </>
      )
    }
  return (
    <Template>{photoGalleryScreen()}</Template>
  )
}

export default PhotoGallery