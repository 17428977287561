import React from 'react'
import Template from '../Components/Template'


function Home() {
    const HomeScreen=()=>{
        <>
        This is home
        </>
    }
  return (
    <Template>
        {HomeScreen()}
    </Template>
  )
}

export default Home