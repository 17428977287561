import React, { useEffect, useState } from 'react'
import Template from '../Components/Template'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDataTable from '../hooks/useDataTable';
import { useAddEventNameMutation, useDeleteEventNameMutation, useGetAllEventsByYearQuery, useUpdateEventNameMutation } from '../Services/modules/PhotoGallery';
import { useDispatch, useSelector } from 'react-redux';
import WarningModal from '../Components/WarningModal';
import { useNavigate } from 'react-router-dom';
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { updateEventDataState } from '../Store/EventData';


function PhotoGalleryEvent() {
  const is_gallery_data=useSelector(state => state.galleryData.gallery_data?true:false);
  const gallery_data=useSelector(state => state.galleryData.gallery_data);  
 const navigate=useNavigate()
 const dispatch=useDispatch()
  console.log("GalleryData",gallery_data)
  const {   
    handleFirstPage ,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex  ,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
    // Add other state variables and functions here
  }=useDataTable()
  const [event_data, setEventData] = useState({
    event_id:"",
    event_name:""  ,
    year_id: gallery_data._id 
  });
  const[isDelete,setIsDelete]=useState(false) 
  const[isDeleteModal,setDeleteModal]=useState(false)
  const[event_list,setEvent]=useState([]) 
  const [isSumbmiting,setIsSubmitting]=useState(false)
  const [isUpdate,setIsUpdate]=useState("")  

  const{
    data,
    isSuccess,
    isLoading,
    error,
   // refetch
  }=useGetAllEventsByYearQuery({page_number:page,page_size:rowsPerPage,year_id:event_data.year_id})
  
  
  const [AddEvent, { data:addEventData, isSuccess:addEventIsSuccess, isLoading:addEventIsLoading, isError:addEventIsError, error:addEventError }] = useAddEventNameMutation();
 
  const [DeleteEvent, { 
    data:deleteEventData=[], 
    isSuccess:deleteEventIsSuccess, 
    isLoading:deleteEventIsLoading, 
    isError:deleteEventIsError, 
    error:deleteEventError }] = useDeleteEventNameMutation();
   
    const [UpdateEvent, { 
      data:updateEventData=[], 
      isSuccess:updateEventIsSuccess, 
      isLoading:updateEventIsLoading, 
      isError:updateEventIsError, 
      error:updateEventError }] = useUpdateEventNameMutation();
      const onSubmit=()=>{
        setIsSubmitting(true)
        if(!isUpdate)
        {
          AddEvent(event_data)

        }
        if(isUpdate)
        {
          UpdateEvent(event_data)

        }
      }
      const clearEventData=()=>{
        console.log("In clear product data")
      
        setEventData({
          ...event_data,
          event_id:"",      
          event_name:""   ,       
        });

      }
      useEffect(()=>{
        if(isSuccess)
        {
          console.log("Event data",data)
          setEvent(data.data)
  setTotalRows(data.totalRows)

        }
      },[data])
      useEffect(()=>{
        if(updateEventIsSuccess)
        {
          toast.success(updateEventData.message)
          setIsSubmitting(false)
          setIsUpdate(false)
          clearEventData()
        }
      },[updateEventData])
      useEffect(()=>{
        if(deleteEventIsSuccess)
        {
          toast.success(deleteEventData.message)
          setIsSubmitting(false)
          setIsUpdate(false)
          clearEventData()
          setIsDelete(false)
          setDeleteModal(false)
        }
      },[deleteEventData])
      useEffect(() => {
        if(isDelete)
        {
          DeleteEvent(event_data)
        }
      
      }, [isDelete]);
      useEffect(()=>{
        if(addEventIsSuccess)
        {
          setIsSubmitting(false)
          toast.success(addEventData.message)
          clearEventData()
          //refetch()
        }
      },[addEventData])
      function handleChange(name, value) {
        setEventData({
          ...event_data,
          [name]: value
        });
      }
      const handleEventEdit=(dataRow)=>{
        console.log("dataRow",dataRow)
        setEventData({
        ...event_data,
        event_id:dataRow._id,
        event_name: dataRow.program_name
     
      });
      setIsUpdate(true)
      
      }
      const handleEventDelete=(dataRow)=>{
       console.log("In handle product delete dataRow",dataRow)
        setDeleteModal(true)
        setEventData({
          ...event_data,
          event_id:dataRow._id,         
        });

      }
      const navigateToEventPhotos=(galleryEventDetails)=>{
        console.log("Gallery details in event",galleryEventDetails)
        dispatch(updateEventDataState({data:galleryEventDetails}))
      // dispatch(updateGalleryData({data:galleryDetails}))

        navigate({
          pathname: '/photogalleryimages',
          // search: `?id=${courseDetails._id}`,    
        });
  
       }

  const photoGalleryEventScreen=()=>{
    return(
      <>
      <ToastContainer/>
      <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
              <main id="main" class="main">
              <div className='container'>
              <div className='row'>
              <div className="col-md-6">
              <div className="card">
            <div className="card-body">
            <h5 className="card-title">Add Product Details</h5>
            <div className="form-group">
                  <label htmlFor="input1">Event Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="event_name"
                  value={event_data.event_name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                   />
                </div>
                <button type="submit" 
                className="btn btn-primary"              
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isUpdate?'Update':
                  'Submit'} 
                 
                </button>
            </div>
                </div>
                </div>
                <div className="col-md-6">
              <div className="card">
              <div className='card-body'>
                        <table className="bordered-table">
        <thead>
          <tr>
          <th>Action</th>
            <th>Index</th>
            <th>Event Name</th>
            {/* Add more column headers as needed */}
          </tr>
        </thead>
        <tbody>
          {event_list.map((item, index) => (
            <tr key={index}>
              <td>
                <MdEdit onClick={()=>handleEventEdit(item)} 
                style={{cursor:'pointer'}}/> 
              <MdDelete onClick={()=>handleEventDelete(item)} style={{cursor:'pointer'}}/>
              </td>
              <td>{sequenceStartIndex + index}</td>
              <td>
              <a onClick={()=>navigateToEventPhotos(item)} style={{cursor:'pointer',color:'blue'}}>

                {item.program_name}
                </a>
                </td>             
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button>
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
      <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button>
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div>
     
                        </div>
                </div>
                </div>
                </div>
                </div>
                </main>
      </>
    )
  }
  return (
    <Template>{photoGalleryEventScreen()}</Template>
  )
}

export default PhotoGalleryEvent