import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

function Sidebar() {
  const location = useLocation();
   
  return (
   <>
   <aside id="sidebar" class="sidebar">

<ul class="sidebar-nav" id="sidebar-nav">
<Link 
    to='/dashboard'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link " style={location.pathname === '/dashboard' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}>
      <i class="bi bi-grid"></i>
      <span>Dashboard</span>
    </a>
  </li>
  </Link>
  <Link 
    to='/products'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/products' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Products</span>
    </a>
  </li>
  </Link>
  <Link 
    to='/photogallery'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/photogallery' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Photo Gallery</span>
    </a>
  </li>
  </Link>
  <Link 
    to='/reports'
    style={{textDecoration:'none'}}
    >
  <li class="nav-item">
    <a class="nav-link"  style={location.pathname === '/reports' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}} >
      <i class="bi bi-grid"></i>
      <span>Reports</span>
    </a>
  </li>
  </Link>


</ul>

</aside>
   </>
  )
}

export default Sidebar